<template>
  <!-- 发布成功提示 -->
  <div class="commonMain">
    <el-dialog
        title="发布成功提示"
        :visible.sync="dialogVisible"
        width="375px"
        :before-close="handleClose"
        center>
        <div>
          您的咨询订单已生成，请耐心等待律师与您联系！
        </div>
        <div slot="footer" class="dialog-footer-g">
          <span @click="close">好的，我知道了</span>
        </div>
      </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
  
  },
  data() {
    return {
      dialogVisible:false,
      type:1, // 1 发布咨询  2 订单付款
      show1:false,
    }
  },

  mounted() {
   
  },

  methods: {
    open(data){
      this.dialogVisible = true;
      if(data == 'gofalse'){
        // 发布成功后，停留在当前页面，并不后退
        this.show1 = true;
      }
    },
    handleClose(){
      this.dialogVisible = false;
    },
    close(){
      this.dialogVisible = false;
      // if(this.show1){
        this.$router.push({
          path: '/zixun'
        });
      // } else  {
      //   this.$router.go(-1); // 后退刷新
      // }
      
    }

  }
}
</script>

<style lang="scss">
.dialog-footer-g{
  cursor: pointer;
  text-align: center;
  border-top: 1px solid #ECECEC;
  padding-top: 17px;
  span{
    font-size: 15px;
    color: #305BFE;
  }
}

</style>