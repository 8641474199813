<template>
  <!-- 热门领域详情页，发布提示 -->
  <div class="commonMain">
    <el-dialog
        title="选择支付方式"
        :visible.sync="dialogVisible"
        width="400px"
        :before-close="handleClose"
        center>
        <div class="fabumainboxpay">
          <div class="fabumainboxpay-titlef">
            <p class="fabumainboxpay-t">{{payinfo.type}}</p>
            <p class="fabumainboxpay-p">￥{{payinfo.price}} </p>
          </div>
          <div class="fabumainboxpay-pay">
            <div class="fabumainboxpay-pay-line">
              <div class="flexbox">
                <img src="@/assets/img/pay_wx.png" alt="" class="wximg">
                <div>微信支付</div>
              </div>
              <img src="@/assets/img/choose.png" v-if="!checked1"
              @click="checkedFun(1)" class="chooseimg">
              <img src="@/assets/img/choosed.png" class="chooseimg"  v-else @click="checkedFun(1)">
            </div>

            <div class="fabumainboxpay-pay-line">
              <div class="flexbox">
                <img src="@/assets/img/pay_zfb.png" alt="" class="wximg">
                <div>支付宝支付</div>
              </div>
              <img src="@/assets/img/choose.png" v-if="!checked2"
              @click="checkedFun(2)" class="chooseimg">
              <img src="@/assets/img/choosed.png" class="chooseimg"  v-else @click="checkedFun(2)">
            </div>

            <div class="fabumainboxpay-pay-line">
              <div class="flexbox">
                <img src="@/assets/img/pay_qb.png" alt="" class="wximg">
                <div>钱包支付  <span>可用余额：¥{{money}}</span> </div>
              </div>
              <img src="@/assets/img/choose.png" v-if="!checked3"
              @click="checkedFun(3)" class="chooseimg">
              <img src="@/assets/img/choosed.png" class="chooseimg"  v-else @click="checkedFun(3)">
            </div>

            <div class="fabumainboxpay-pay-line">
              <div class="flexbox">
                <img src="@/assets/img/qyi-icon.png" alt="" class="wximg">
                <div>权益支付(会员专属)</div>
              </div>
              <img src="@/assets/img/choose.png" v-if="!checked5"
              @click="checkedFun(5)" class="chooseimg">
              <img src="@/assets/img/choosed.png" class="chooseimg"  v-else @click="checkedFun(5)">
            </div>
          </div>
         
        </div>
        <div slot="footer" class="dialog-footer-ggpay">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click.stop="topay">确 定</el-button>
        </div>
    </el-dialog>

    <!-- 免费发起 -->
    <el-dialog
        title="真的要放弃购买吗？"
        :visible.sync="dialogVisibleFree"
        width="375px"
        :before-close="handleCloseFree"
        center>
        <div class="fabumainbox">
          <p>写了这么久，真的要离开吗？付费咨询可帮助您深入解决问题。如若尚未决定付费可免费发起</p>
        </div>
        <div slot="footer" class="dialog-footer-gg1 flexbox">
          <span @click.stop="tofree" class="btnbox cancelbtn">免费发起</span>
          <span @click="contain" class="btnbox">继续付费</span>
        </div>
      </el-dialog>

    <!-- 微信支付二维码弹窗 -->
    <scan-pay-code v-if="showPay" @close="closePayModal" :img="payImg" :type="paytypeText"></scan-pay-code>
    <!-- 发布去付费时显示  -->
    <fabutip ref="fabutips" @toshow="toshow"></fabutip>

    <!-- 发布成功后显示  -->
    <successtip ref="fabusuccesstip"></successtip>

  </div>
</template>

<script>
import apiUrl from '@/api/main'
import ScanPayCode from '@/components/ScanPayCode';
import fabutip from '@/components/tip/fabu';
import successtip from '@/components/tip/fabusuccess'

import base from '@/config/env' // 引入域名

export default {
  name: '',
  components: {ScanPayCode,fabutip,successtip},
  props: {
    // 我的咨询页面去付款，2不显示 免费发起  默认1 显示
    typeNum: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
        dialogVisible:false,
        dialogVisibleFree:false, // 免费发起
        value: '',
        type:'', // 1 微信 2 支付宝 3 钱包
        checked1:false,
        checked2:false,
        checked3:false,
        checked5:false,
        payinfo:{},
        money:0, // 账户余额
        showPay:false, // 微信支付弹窗
        payImg:'', // 支付二维码 
        paytypeText:'微信', // 微信、支付宝
        orderid:'', // 订单id
        orderNo:'', // 订单编号
        orderpay:false, // 是否是 订单支付页
    }
  },

  mounted() {
   
  },

  methods: {
    open(data,id){
      if(data){
        this.payinfo = data;
      }
      // 订单id
      if(id){
        this.orderid = id;
      }
      this.dialogVisible = true;
      this.getbalance();
      this.orderpay = false;
    },
    openpay(data,id){
      if(data){
        this.payinfo = data;
      }
      // 订单id
      if(id){
        this.orderid = id;
      }
      this.dialogVisible = true;
      this.getbalance();
      this.orderpay = true;
    },
    handleClose(){
      this.dialogVisible = false;
      this.type = '';
      this.checked1 = false;
      this.checked2 = false;
      this.checked3 = false;


      if(this.typeNum == 1){
        this.dialogVisibleFree = true;
      }
    },
    //  关闭
    handleCloseFree(){
      this.dialogVisibleFree = false;
    },
    // 继续付费
    contain(){
      this.dialogVisibleFree = false;
      this.dialogVisible = true;
    },
    // 免费发起  放弃付费 选择免费咨询
    tofree(){
      this.dialogVisibleFree = false;
      this.$emit('tofree');
    },
    // 1 微信 2 支付宝 3 钱包 5 权益
    checkedFun(val){
      if(val == 1){
        this.checked2 = false;
        this.checked3 = false;
        this.checked5 = false;
      }else if(val == 2){
        this.checked1 = false;
        this.checked3 = false;
        this.checked5 = false;
      }else if(val == 3){
        this.checked1 = false;
        this.checked2 = false;
        this.checked5 = false;
      }else if(val == 5){
        this.checked1 = false;
        this.checked2 = false;
        this.checked3 = false;
      }
      this[`checked${val}`] = ! this[`checked${val}`];

      if( this[`checked${val}`]){
        this.type = val;
      }else {
        this.type = ''
      }
      
    },
    // 获取用户钱包可用余额
    getbalance(){
      let data = {
        userId: localStorage.getItem('userId')
      }
      apiUrl.userInfo(data).then((res) => {
        this.money = res.data.money;
        localStorage.setItem("userinfo", JSON.stringify(res.data));
      })
    },
    // 去付费 回调
    toshow(data){
      this.dialogVisible = true;
      this.payinfo = data;
    },

    /**
     * 支付 1 微信 2 支付宝 3 钱包 5 权益
     * 接口传参支付方式 0微信 1钱包   6微信  7支付宝
     * 
     *  // this.dialogVisible = false;
      //     // this.paytypeText = '微信'
     * */ 
    topay(){
      if(this.type == '') {
        this.$message.error('请选择支付方式');
        return;
      }
      if(this.type == 3 && this.money <= 0){
        this.$message.error('余额不足');
        return;
      }
      
      // 如果没有订单id
      if(!this.orderid && localStorage.getItem('submitData')){
        let subdata = JSON.parse(localStorage.getItem('submitData'))
        let submitdata = {
          userId:subdata.userId,
          fenglei:subdata.fenglei,
          content:subdata.content,
          yuejianaddr:subdata.yuejianaddr,
          yuejianphone:subdata.yuejianphone,
          lawyerId:subdata.lawyerId,
          photos:subdata.photos,
          wenshubiaoqian:subdata.wenshubiaoqian,
          type: this.payinfo.type,
          price:this.payinfo.price
        }
        apiUrl.addorder(submitdata).then((res) => {
         if(res.code == 0){
             this.orderid = res.data.id;
             console.log('有订单id', this.orderid,res);
             this.paypay();
          } 
        });
      }

      if(this.orderid) {
        this.paypay();
      }


    },

    // 支付 1 微信 2 支付宝 3 钱包 5 权益
    // 接口传参支付方式 0微信 1钱包   6微信  7支付宝
    paypay(){
      let payType = '';
      let that = this;
      if(this.type == 1){
        payType = '6' //  pc微信支付 payType=6
      } else if(this.type == 2){
        payType = '7'  // 支付宝  payType=7
      } else if(this.type == 3){
        payType = '1'
      } else if(this.type == 5){
        payType = '5'
      } 

      let data = {
        id: this.orderid,
        payType: payType
      }

      // 钱包支付 和 权益支付
      if(this.type == 3 || this.type == 5 ){
        apiUrl.orderpay(data).then((res) => {
          this.dialogVisible = false;
          if(res.code == 0){
            // 咨询成功后显示
           
            if(this.orderpay == true){
              this.$message.success('支付成功');
              this.$emit('refresh');
            } else {
              this.$refs.fabusuccesstip.open();
            }
          }
          
        });
      } 
      // this.type == 1 微信支付
      if(this.type == 1){
        apiUrl.orderpay(data).then((res) => {
          this.dialogVisible = false;
          this.showPay = true;
          if(res.code == 0){
            this.orderNo = res.lsFuwuOrder.orderNo;
            // 拼接微信支付二维码
            this.payImg =  base.baseURL + 'api/getQRCode?codeUrl=' + res.orderString;

            
            this.timer = setInterval(() => {
              
              // 订单详情 /api/orderInfo    id 订单id   返回参数  payStatus支付状态 0未支付 1已支付
              let data = {
                id: that.orderid
              }
              apiUrl.orderInfo(data).then((res) => {
                if(this.orderpay == true){
                  if(res.data.payStatus == 1){
                    this.showPay = false;
                    this.dialogVisible = false;
                    clearInterval(this.timer);
                    this.$message.success('支付成功');
                    this.$emit('refresh');
                  }
                } else {
                  if(res.data.payStatus == 1){
                      clearInterval(this.timer);
                      this.showPay = false;
                      this.dialogVisible = false;
                      this.$message.success('支付成功');
                      this.$refs.fabusuccesstip.open();
                  }
                }
              });
            }, 1000);

             
          }
        });
      }

      // this.type == 2 支付宝支付
      if(this.type == 2){
        apiUrl.orderpay(data).then((res) => {

          document.querySelector("body").innerHTML = res.orderString;
          document.forms[0].submit();
          
        });
      }
    },
    // 关闭 微信支付二维码弹窗
    closePayModal(){

      this.showPay = false;
      this.dialogVisible = false;

      // 订单详情 /api/orderInfo    id 订单id   返回参数  payStatus支付状态 0未支付 1已支付
      let data = {
        id: this.orderid
      }
      apiUrl.orderInfo(data).then((res) => {
        if(this.orderpay == true){
          if(res.data.payStatus == 1){
            // this.$message.success('支付成功');
            // this.$emit('refresh');
          } else {
            this.$message.error('支付失败');
          }
        } else {
          if(res.data.payStatus == 1){
              // this.$refs.fabusuccesstip.open();
          } else {
              this.$message.error('支付失败');
              this.dialogVisibleFree = true;
          }
        }
       
      });

    },
    
    cancel(){
      this.dialogVisible = false;
      if(this.typeNum == 1){
        this.dialogVisibleFree = true;
      }
    }
    
   
  }
}
</script>

<style lang="scss">
.fabumainboxpay{
  text-align: center;
  .fabumainboxpay-titlef{
    .fabumainboxpay-t{
      font-size: 17px;
      font-weight: 400;
      color: #333333;
    }
    .fabumainboxpay-p{
      font-size: 22px;
      font-weight: 500;
      color: #EF0000;
      margin-top: 10px;
    }
  }
  .fabumainboxpay-pay{
    border-top: 1px solid #EEEEEE;
    margin-top: 20px;
    padding-top: 31px;
    .fabumainboxpay-pay-line{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 27px;
      .wximg{
        width: 27px;
        height: 27px;
        margin-right: 27px;
      }
      div{
        font-size: 13px;
        font-weight: 400;
        color: #333333;
        span{
          font-size: 12px;
          color: #777777;
          margin-left: 13px;
        }
      }
      .chooseimg{
        width: 20px;
        height: 20px;
      }
    }
  }

}
.dialog-footer-ggpay{
  .el-button{
    width: 40%;
  }
}

.dialog-footer-gg1{
  .btnbox{
    width:160px;
    margin: 0 10px;
    height: 45px;
    line-height: 45px;
  }
  .cancelbtn{
    background: #F4F4F4;
    color: #4C4A48;
  }
}




</style>