<template>
  <!-- 热门领域详情页，发布提示 -->
  <div class="commonMain">
    <el-dialog
        title="发布提示"
        :visible.sync="dialogVisible"
        width="375px"
        :before-close="handleClose"
        center>
        <div class="fabumainbox">
          <p>您当日的免费咨询次数已使用完毕，请选择付费咨询！</p>
          <el-select v-model="value" placeholder="请选择咨询方式" @change="fuwuChange">
            <el-option
              v-for="item in Fuwulist"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
         
        </div>
        <div slot="footer" class="dialog-footer-gg">
          <span @click="topay" class="btnbox">去付费</span>
        </div>
      </el-dialog>

 
  </div>
</template>

<script>
import apiUrl from '@/api/main'

export default {
  name: 'fabu',
  components: {},
  props: {
  
  },
  data() {
    return {
      dialogVisible:false,
      Fuwulist:[],
      value: '',
      payinfo:{}
    }
  },

  mounted() {
    this.getlsFuwulist(); // 咨询方式 服务
  },

  methods: {
    open(){
      this.value = '';
      this.dialogVisible = true;
    },

    handleClose(){
      this.dialogVisible = false;
    },

    // 去付费
    topay(){
      if(!this.value){
        return;
      }
      this.dialogVisible = false;
      this.$emit('toshow', this.payinfo);
    },

    // 获取 精选服务 咨询方式
    getlsFuwulist() {
      let data = { }
      apiUrl.lsFuwulist(data).then((res) => {
          this.Fuwulist = res.rows;
      })
    },
     // 选择咨询方式
     fuwuChange(e){
      this.Fuwulist.forEach(element => {
        if(element.name == e){
          this.payinfo.price = element.price;
          this.payinfo.type = element.name;
        }
      });
    
    },
  }
}
</script>

<style lang="scss">
.fabumainbox{
  p{
    margin-bottom: 27px;
    color: #333333;
    font-size: 13px;
  }
  .el-select{
    width: 333px;
  }
  .el-input__inner{
    background-color: #fff !important;
    border: 1px solid #DCDFE6 !important;
  }

}
.dialog-footer-gg{
  cursor: pointer;
  text-align: center;
  padding-top: 17px;
  span{
    display: inline-block;
    width: 267px;
    height: 47px;
    line-height: 47px;
    border-radius: 23px;
    font-size: 13px;
    font-weight: 500;
    color: #FFFFFF;
  }
  .btnbox{
    height: 47px;
    line-height: 47px;
  }
}





</style>